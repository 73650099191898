const ServicesInfo = [  
    {
        "serviceId": 1,
        "title": "Satyanarayan Puja",      
        "titleSub": "Get in touch with the best Durga Puja Purohit in Bangalore",  
        "description": "If you want to perform Satyanarayan puja the first thing which you need is Shaligram Shila or Narayan Shila. Only you can find it in KaliBari, Bangalore.",                
        "description_Full": "If you want to perform Satyanarayan puja the first thing which you need is Shaligram Shila or Narayan Shila. Only you can find it in KaliBari. Dr Shivshankar Bhattacharya, the priest of Electronic City KaliBari used to perform this ritual with full of devotion. If you want to perform Satyanarayan puja at your home, you may contact with him and he will bring Shaligram Shila from the KaliBari to your home. The Satyanarayan Puja is a religious worship of the Hindu god Vishnu. Satya means “truth” and narayana means, “The highest being” so Satyanarayan means “The highest being who is an embodiment of Truth”. Vrat or Puja means a religious vow, religious observance, or obligation. Hindus throughout perform Sri Satyanarayan Vrat for the divine blessings of health, wealth, prosperity, opulence, education; relief from troubles and sickness. It can also be performed because of success in business or career growth; during social functions like marriages, house-warming ceremonies, naming of the children and so on.",
        "imgUrl": "/assets/img/services/1.jpg"
    },     
    {
        "serviceId": 2,
        "title": "Griha Prabesh", 
        "titleSub": "Get in touch with the best Durga Puja Purohit in Bangalore",
        "description": "During Griha Pravesh you must need to worship Vastu, Lord Vishu, Ganapati, Laxmi, Navagraha, Chandi, Pancha Dev Devi and Homa.",
        "description_Full": "Voluptatem perferendis sed assumenda voluptatibus. Laudantium molestiae sint. Doloremque odio dolore dolore sit. Quae labore alias ea omnis ex expedita sapiente molestias atque. Optio voluptas et.Aboriosam inventore dolorem inventore nam est esse. Aperiam voluptatem nisi molestias laborum ut. Porro dignissimos eum. Tempore dolores minus unde est voluptatum incidunt ut aperiam.Et dolore blanditiis officiis non quod id possimus. Optio non commodi alias sint culpa sapiente nihil ipsa magnam. Qui eum alias provident omnis incidunt aut. Eius et officia corrupti omnis error vel quia omnis velit. In qui debitis autem aperiam voluptates unde sunt et facilis.",                
        "imgUrl": "/assets/img/services/2.jpg"
    },
    {
        "serviceId": 3,
        "title": "Marriage",        
        "titleSub": "Get in touch with the best Durga Puja Purohit in Bangalore",
        "description": "2000 plus marriage ceremony of different culture, conducted by Purohitmoshay ShivShankar Bhattacharya ",                
        "description_Full": "Do you reside in Bangalore? Do you want to find out a purohit for marriage in Bangalore? If so, then you have come at the right place. Here you will get familiar with the most reputed purohit in Bangalore to complete marriage or other social function with great satisfaction. Nowadays, people are facing problem in getting a purohit in their local areas but we are available online to be discovered by you with a click. If you search online then you will easily reveal us for the popularity of services in Bangalore, offered by our Purohits. With an aim to get the best support from a renowned purohit to complete a marriage ceremony with great satisfaction, if you search online then you will reveal us.",
        "imgUrl": "/assets/img/services/3.jpg"
    },
    {
        "serviceId": 4,
        "title": "Shanti Swastayan", 
        "titleSub": "Get in touch with the best Durga Puja Purohit in Bangalore",
        "description": "Dr Shiv Shankar Bhattacharya is an experienced priest to perform this ceremony",
        "description_Full": "Shanti Swastayan is a special Puja seeking blessing from the supreme God the Bramha, the Vishnu and the Maheswara. Dr Shiv Shankar Bhattacharya is an experienced priest to perform this ceremony. A lot of people concat him for this. Worshiping these Gods in the right way is an ART and he is the picasso in this field. If you too are searching for a knowledegeable purohit in Bangalore then this person can be the great choice for you. But in order to book the appointment you need to do a bit hurry as he is popular and a lot of people wish him to do the puja at their places.  In this website you will get all the required information to contact him such as his field of expertise, educational background, charges and all.",                
        "imgUrl": "/assets/img/services/4.jpg"
    },
    {
        "serviceId": 5,
        "title": "Shradhha",        
        "titleSub": "Get in touch with the best Durga Puja Purohit in Bangalore",
        "description": "If you want to get the finest opportunity to meet the religious needs to perform this ceremony Dr Shivsankar Bhattacharya is a specialist in this.",                
        "description_Full": "Śrāddha (Sanskrit: श्राद्ध) is a Sanskrit word which literally means anything or any act that is performed with all sincerity and absolute faith in it. (Śraddhā). Bangalore, which is popularly known as IT city in India, is the residence of many migrant Bengalis. They want to get in touch with the best pandit in Bangalore to complete this ceremony. In the Hindu religion, it is the ritual that one performs to pay homage to one's 'ancestors' (Sanskrit: Pitṛs), especially to one's dead parents. Conceptually, it is a way for people to express heartfelt gratitude and thanks towards their parents and ancestors, for having helped them to be what they are and praying for their peace. It also can be thought of as a 'day of remembrance'. It is performed for both the father and mother separately, on their respective 'thithi' - death anniversaries as per Hindu calendar. Therefore, if you want to get the finest opportunity to meet the religious needs to perform this ceremony then surely you will be interested in contacting the best pandits in Bangalore.",
        "imgUrl": "/assets/img/services/5.jpg"
    },
    {
        "serviceId": 6,
        "title": "Kali Puja",        
        "description": "You can easily Book Purohitmoshay for Kali Puja online and can perform the worship as per your will.",                
        "description_Full": "In order to worship, there are several rituals to be followed as per the Vedic laws and these can be well done by an experienced Pandit for Kali Puja. You can easily Book Purohitmoshay for Kali Puja online and can perform the worship as per your will. There are various materials required to perform the puja and these people are masters of all kinds of religious details. You can get the best kind of Purohitmoshay for Kali Puja in Bangalore Who have a great deal of knowledge related to all the Hindu rituals that are required to perform to welcome Goddess Ma Kali. ",
        "imgUrl": "/assets/img/services/6.jpg"
    },    
    {
        "serviceId": 7,
        "title": "Durga Puja",        
        "description": "If you are in need of the best purohit moshai for this upcoming Durga Puja then Dr. Shib Shankar Bhattacharya is the best choice.",                
        "description_Full": "The time has come for which, the entire Bengali community waits! Finally, Durga Puja has come and now it’s the time to pray to Maa Durga to have a great and successful life. Durga Puja is not only a tradition to the Bengalis but also it has become an emotion. The joy of this great festival is not only limited to the Bengalis but also a lot of people from various communities join and feel the amazingness of Durga Puja. Dr. Shib Shankar Bhattacharya is a renowned Purohit. He is a Vedic priest and has mastered in a lot of genres such as Purohitya, Tantra, and Astropalmistry. All the art of praying, he has mastered and if you want to pray with him then just contact him and experience a great worship. He is in this domain over years and has lots of followers due to his devoted works.",
        "imgUrl": "/assets/img/services/7.jpg"
    },
    {
        "serviceId": 8,
        "title": "Upanayan",        
        "description": "Dr. Shib Shankar Bhattacharya is a renowned Purohit to perform this rituals.",                
        "description_Full": "Upanayana (Sanskrit: उपनयन upanayana-) Janai or janeau or poita or Yagnopavita (Sanskrit: जनै, जनेऊ) is one of the traditional saṃskāras (rites of passage) that marked the acceptance of a student by a guru (teacher or tutor) and an individual's entrance to a school in Hinduism. The tradition is widely discussed in ancient Sanskrit texts of Hinduism and varies regionally. The sacred thread (yagyopavita or janeu) is received by the boy during this ceremony, that he continues wearing from left shoulder to the right crossing the chest thereafter. Generally this ceremony should be done before the age of 16. Dr. Shib Shankar Bhattacharya is a renowned Purohit. He is a Vedic priest and has mastered in a lot of genres such as Purohitya, Tantra, and Astropalmistry. All the art of praying, he has mastered and if you want to pray with him then just contact him and experience a great worship. So, if you are looking for the best purohit moshay for Upanayana Puja then he is the best choice for you.",
        "imgUrl": "/assets/img/services/8.jpg"
    },
    {
        "serviceId": 9,
        "title": "Annaprasan",        
        "description": "If you are in Bangalore then ShibShankar Bhattacharya can help you out the best.",                
        "description_Full": "The Annaprashana (Sanskrit: अन्नप्राशन, Annaprāśana, Bengali: অন্নপ্রাশন, Onnoprashon) also known as Annaprashana vidhi, Annaprasan or Anna-prasanam or Anna Prashashan, is a Hindu ritual (Saṃskāra) that marks an infant's first intake of food other than milk. The term annaprashan literally means 'food feeding' or 'eating of food'.The Annaprashana, unlike many other Samskaras, remains an important ceremony in modern India. If you are in Bangalore then ShibShankar Bhattacharya can help you out the best. Actually, when you are out of the Bengal then getting an efficient purohit with years of practical knowledge and great educational background is very much tough. And that’s why a lot of people who are in search of the best pandit for Annaprashana in Bangalore they contact ShibShankar Bhattacharya at the first move.",
        "imgUrl": "/assets/img/services/9.jpg"
    },
    
]
export default ServicesInfo;