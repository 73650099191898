const aboutData = {       
    kalibari: {
                aboutTitle: "About Kalibari",
                content1: `In the electronic city, Bangalore in India if you want to meet Kali Ma then come to the famous Bangalore Kali Bari. You will feel the satisfaction of life. You will feel that a Godly hand is always there to help you. In order to ensure the best wellbeing of you and your family members, you can arrange Puja at the famous electronic city Kali Bari. We are here to conduct the puja and thus to help you getting the best blessings from the Kali Ma. In your day to day life, if you are feeling dissatisfied, then don’t worry. God is there to help you always. Just make a call in a proper way and you will attain the strength to face the challenges in your life. Uncountable number of people comes here every day to attain the best blessings from their beloved Kali Ma.`,
                content2: `PurohitMoshay in Bangalore always strives to help many through his sadhanas and educational knowledge so that you can spend your life happily and peacefully. Let him know your problems and help you getting the best options to overcome the life challenges and day to day life worries. You may be a new comer in Bangalore and may have desire to visit the most famous Kali Mandir Bangalore. If so, then don’t delay to make your desire come true. Let your good luck help you by conducting a puja in the Kali mandir Bangalore.`,
        },
    purohitMoshai: {
            title: "About PurohitMoshay",
            content1: `PurohitMoshay spent his early life in Kashi, Varanasi, Akhil Bhartiya Kashi Vishwanath Dandi Sanyasi Seva Samiti  Swami Baneshwara Nanda Tirtha, as his chief disciple and “Shishya Pratinidhi”. All four Pithadhishwar Sankaracharya like Kedar Badri, Puri, Dwarka, Shringeri  are all Dandi Sanyasi for this reason, in many places in India, Dandi Swami and Monks are united. Acharya ji has solved many problems of Monks, by taking guidance from his Gurudev, Sri Baneshwara Nanda ji. During that time he has come in contact with Kedar Badri Sankaracharya, Dandiswami Madhavashrama Ji Maharaj, Dwarka Shankaracharya, Swarupananda Ji Maharaj and his “Pradhan Shishya Pratinidhi” Swami Abhimukteshwarananda Ji, Puri Shankaracharya, Dandiswami Nischalananda Ji Maharaj, Shringeri Sankaracharya Bharathi Tirtha Maharaj, Ananta Shri vimalanandaji Maharaj, Shri Vidyaupasak, Anantashrimodananda Swami Rishikesh Asram Maharaj, Mahanta Tarkeshwar Math, Kashi Vishwanath Samiti Sampadak Chandra Mauli Upadhaya.`,
            content2: `PurohitMoshay was associated with Maa Bhavatarini, Kali bari, Jayant, Singrauli, M.P.(N.C.L.) from 4th June 2002 till 3rd October 2009. He again returned to Varanasi and met Gurudev Baneshwarananda Ji Maharaj, and became Chief Priest of Manikarnika, Kali Bari. While being in Varanasi her gained knowledge in the field of Astrology, Veda, Palmistry, and traditional tantra of Varanasi. He was responsible for daily worship of Kashi Vishwanath Temple.`,
            content3: `He performed his first Durga Puja at the age of 14 years, in Delhi, R.K.Puram. He also assumed the role of priest in the Matrika Ashram in New Delhi, Maa Dakshina Kali Temple. Acharyaji also performed special puja in Kamakhya Mandir, as ordered by Dr. Durga Prasad Sharma (Chief preist of Kamrup Kamakhya Temple).`,
            content4: `He has also performed a special puja in Kalighat on 31st Dec of 2009 at midnight, which was organised by the Indian Occidental Cymbiosis, and had gained Rajtilak on the same day. Tarapith Mahapandit and the President of Tarapith Purohit Snagha had ordered Acharya Ji to come to Tarapith and do worship of Maa Tara “Shodosopochar” and Maha Arati, and also performed Puja Anushthan for Bamakhypa in “Swet Simultala” and conducted “Dharma Shabha”. In 2010, in Haridwar, Maha Kumbha, as organised by Kashi Vishwanath Akhil Bhartiya Dandi Sanyasi Seva Samiti, in place (Gita Kutir), Acharya Ji was honoured as “Kashi Ratna” . He came down to Vrindavan and performed special puja of Banke Bihari, Radha, Balramji on the occasion of Holi.`,
            content5: `PurohitMoshay has learnt the vedic puja rituals and Tantra Puja in kashi Varanashi, Kamrum math, Dashashwamedh Ghat. Acharya Ji has been associated with Late Mr. Arun Kumar Lahiri and Mr. Manoj Kumar Lahiri (S/o Mr. N.C. Lahiri) as Respected Chief “PristoPoshok” in Vishudhha Sidhdhanta Panjika. He has been associated and has headed the establishment (Prana Pratishtha) of many temples across India along with his team of preists.`,
    }
};

export default aboutData