const GalaryInfo = [
    {
        "imgUrl": "/assets/img/gallery/1.jpg",
        "videoUrl": "https://www.youtube.com/embed/QmjdjtyKSoc"
    },
    {
        "imgUrl": "/assets/img/gallery/2.jpg",
        "videoUrl": "https://www.youtube.com/embed/qji03kMXroc"
    },
    {
        "imgUrl": "/assets/img/gallery/3.jpg",
        "videoUrl": "https://www.youtube.com/embed/84e35NJRyJA"
    },
    {
        "imgUrl": "/assets/img/gallery/4.jpg",
        "videoUrl": "https://www.youtube.com/embed/zRhqxKiA-gA"
    },
    {
        "imgUrl": "/assets/img/gallery/5.jpg",
        "videoUrl": "https://www.youtube.com/embed/vtASrWjradQ"
    },
    {
        "imgUrl": "/assets/img/gallery/6.jpg",
        "videoUrl": "https://www.youtube.com/embed/jdhveGmsF80"
    },
    {
        "imgUrl": "/assets/img/gallery/7.jpg",
        "videoUrl": "https://www.youtube.com/embed/5DHL_Ovgy3g"
    },
    {
        "imgUrl": "/assets/img/gallery/8.jpg",
        "videoUrl": "https://www.youtube.com/embed/Uzv4k_Gmp7U"
    }
]
export default GalaryInfo