const faqData = {       
    rows: [
        {
            title: "How to book Purohit for Puja.",
            content: `You can find the Contact no in this website please call this no. Otherwise click the book button from the home page of this website. It will directly send your querries through whatsapp.`,
        },
        {
            title: "Can I Pay Partial Amount As Advance To Confirm The Poojas And Pay The Balance As Cash Or Pay Online After The Puja?",
            content:
                "Yes, you can pay the partial amount as token advance to confirm your booking. You can pay the balance amount after the rituals with cash directly to our Priests.",
        },
        {
            title: "How Long Does The Puja/Havan Take To Complete?",
            content: `It depends on the Puja you select.`,
        },
        {
            title: "What All Items I Need To Keep Ready For The Puja, How Will I Know?",
            content: "As soon as you book Purohitmoshay by paying advance or full amount you will be receiving a To-do List in your whatsapp in few hours, which will clearly mention all the required items. ",
        },
        {
            title: "What All Materials Will The Priest Bring Along With Him?",
            content: "You can book purohitmoshay with samagri or without samagri. ",
        },
        {
            title: "Do I Need To Pay Any Extra Dakshina To The Priest?",
            content: "No, you don’t need to pay any extra amount as dakshina as this will be already included in the package.",
        },
        {
            title: "Do I Need To Pay Extra For The Travel Charge?",
            content: "No, you don’t need to pay extra for the travel charges. but if the pooja is very very far and out of our service area limit , then you need to pay the extra charge which you can ask with the support team while booking.",
        },
        {
            title: "Can he perform pujas for all the different hindu Sanatan cultural rituals?",
            content: "Purohitmoshay Shivshankar Bhattacharya spent his earlier life in Kashi Shankar Math. So he is an expert to speak differnt Indian Languages like Hindi,Bengali,Odia,Rajasthani, Punjabi, Assamese, Tripura etc. He can able to perform all the Santan Cultural rituals.",
        },
        {
            title: "What Will I Do If The Pandit Is Not Coming On Time?",
            content: "Most of the time our Pandits will be before the given time, but sometimes due to some unavoidable circumstances pandits will be a bit late but they will reach your place so you can be assured they will come.",
        },
        {
            title: "How can I do sankalp puja if I am in outside or in abroad.",
            content: "In that case we have famous Kalibari temple in Bangalore Electronic city and Purohitmoshay Shivshankar is the owner of this temple. Your puja will get done after your booking confirmation.",
        },
    ],
};

export default faqData